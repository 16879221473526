html[data-theme='dark'] .ql-stroke {
  stroke: var(--text-color) !important;
}

html[data-theme='dark'] .ql-editor p, .ql-snow, .ql-picker {
  color: var(--text-color) !important;
}

html[data-theme='dark'] .ql-fill, .ql-color-label {
  fill: var(--text-color) !important;
}