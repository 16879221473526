* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  display: block;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

html {
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.87);
}

button,
input,
select,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

@media only screen and (min-width: 600px) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    border-radius: 34px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c7c7c7;
    background-clip: padding-box;
    border-radius: 10px;
    padding: 0 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #878787;
  }
}

html {
  /* MAIN */
  --main-color: #648CA5;
  /* SURFACE */
  --surface-color: #4d7183;
  --surface-color-lighten: #7EA2BD;
  /* BUTTON */
  --button-color: #648CA5;
  --button-color-lighten: #84a4b8;
  /* TEXT */
  --text-color: #26325c;
  --text-inverted-color: #fff;
  --text-secondary-color: #83899F;
  --text-tertiary-color: #26325c;
  /* BACKGROUND */
  --background-color: #fff;
  --background-tertiary-color: #f3f3f3;
  --background-color-disabled: #f1f1f1;
  /* LINE */
  --line-color: #E8EBEF;
  /* BORDER */
  --border-color: #7F91A4;
  /* INPUT */
  --input-label-color: #83899F;
  --input-label-color-disabled: #b8b8b5;
  --input-element-color: #83899F;
  --input-element-color-disabled: #b8b8b5;
  --input-read-only-bg: #F4F4F4;
  /* LIST */
  --list-text-color: #0d111f;
  --list-line-color: #e6e6e6;
  --list-line-color-variant: #767676;
  --list-active-row: var(--surface-color);
  --list-outline-color: #7cb5ff;
  --list-red-bg: #f4cccb;
  --list-green-bg: #b8e4cc;
  --list-orange-bg: #f7d4b6;
  --list-yellow-bg: #fff3cc;
  --list-purple-bg: #c7b8e4;
  --list-blue-bg: #a7c7e7;
  /* COLORS */
  --grey-color: #83899F;

  /* General Variables  */

  /* BUTTON  */
  --button-text-color: #fff;
  --alert-button-bg: #E57373;
  --cancel-button-text: #8e8e8e;
  --cancel-button-border: 1px solid #8e8e8e;
  --cancel-button-bg: #fff;
  /* TEXT */
  --text-color-variant: #99a7b6;
  /* COLORS */
  --red-color: #f44336;
  --orange-color: #EB501E;
  --red-lighten-color: #E57373;
  --green-lighten-color: #26A69A;
  --blue-lighten-color: #29b6f6;
  --purple-lighten-color: #ba68c8;
  --pink-lighten-color: #f06292;
  --orange-lighten-color: #ffb74d;
  --yellow-lighten-color: #fff176;
  /* INPUT */
  --input-text-color: var(--text-color);
  --input-focused-color: var(--main-color);
  --input-element-color-variant: #fff;
  --input-element-focused-color: #ffeb3b;
  /* REST */
  --box-shadow: 0px 4px 12px rgba(5, 12, 38, 0.12);
  --border-radius-large: 12px;
  --border-radius-medium: 8px;
  --border-radius-small: 4px;
  --transition-duration: 0.2s;
  --transition-function: ease-in-out;
  --text-color-disabled: #908e91;
  --border-color-disabled: #b8b8b5;
}

html:not([data-theme='light']) {
  /* MAIN */
  --main-color: #7177F8;
  /* SURFACE */
  --surface-color: #242F3D;
  --surface-color-lighten: #39495E;
  /* BUTTON */
  --button-color: #7177F8;
  --button-color-lighten: #a2a6fa;
  /* TEXT */
  --text-color: #fff;
  --text-inverted-color: #26325c;
  --text-secondary-color: #7F91A4;
  --text-tertiary-color: #7F91A4;
  /* BACKGROUND */
  --background-color: #17212B;
  --background-tertiary-color: #242F3D;
  --background-color-disabled: #4a5867;
  /* LINE */
  --line-color: #2C3743;
  /* BORDER */
  --border-color: #83899F;
  /* INPUT */
  --input-label-color: #c6d0d5;
  --input-element-color: #7F91A4;
  --input-read-only-bg: #4a5867;
  /* LIST */
  --list-text-color: #c6d0d5;
  --list-line-color: #2C3743;
  --list-line-color-variant: #c6d0d5;
  --list-active-row: #39495E;
  --list-outline-color: #7cb5ff;
  --list-red-bg: #8B575C;
  --list-green-bg: #5A6F5E;
  --list-orange-bg: #B27D5C;
  --list-yellow-bg: #B3A580;
  --list-purple-bg: #6B5975;
  --list-blue-bg: #39495E;
  /* COLORS */
  --grey-color: #7F91A4;
}